import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import RoomInformation from '../RoomInformation'

const ContactPageTemplate = () => {
  return <div>
    <Helmet>
      <title>Contact | Dr. Piera Taylor</title>
      <meta name='keywords' content='surgeon, ENT, paediatric, Sydney, doctor, Piera Taylor, 
      cleft palate, hearing issues, salivary management, grommets'/>
      <meta name='description' content="Dr. Piera Taylor, one of Sydney's leading Paediatric ENT Surgeons, specialises in salivary management, cleft palate and ENT conditions for children with diabilities and syndromes."/>
      <meta name='robots' content='index,follow'/>
    </Helmet>
    <section className='hero is-primary is-small'>
      <div className='hero-body'>
        <div className='container'>
          <div className='columns'>
            <div className='column is-10 is-offset-1'>
              <div className='section'>
                <h1 className='title'>
                  Contact Us
                </h1>
                <h2 className='subtitle is-italic'>
                  Please Call to Book an Appointment
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <RoomInformation />
  </div>
}

ContactPageTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
}

export default ContactPageTemplate
