import React from 'react'
import ContactPageTemplate from '../components/ContactPageTemplate'
import Layout from '../components/Layout'

const ContactPage = () => {
  return (
    <Layout>
      <ContactPageTemplate/>
    </Layout>
  )
}

export default ContactPage